

export const K_TRAILER_EMBEDDED_RULE_TYPE = "trailerEmbed"
export const K_THUMBNAIL_EMBEDDED_RULE_TYPE = "thumbnailEmbed"
export const K_PLAY_PERIOD_RULE_TYPE = "playPeriod"
export const K_PRIORITY_RULE_TYPE = "priority"
export const K_SYSTEMS_RULE_TYPE = "systems"

const ruleTypes = [
  { 
    name: "Trailer Embed",
    value: K_TRAILER_EMBEDDED_RULE_TYPE
  },
  {
    name: "Thumbnail Embed",
    value: K_THUMBNAIL_EMBEDDED_RULE_TYPE
  },
  {
    name: "Play Period",
    value: K_PLAY_PERIOD_RULE_TYPE
  },
  {
    name: "Priority",
    value: K_PRIORITY_RULE_TYPE
  },
  {
    name: "Systems",
    value: K_SYSTEMS_RULE_TYPE
  }
]

export default ruleTypes