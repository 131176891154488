

export const getSectionsSortedByTree = ({ sections, sectionsTree }) => {
  return Object.keys(sections).map((sectionId) => sections[sectionId]).sort((a, b) => {
    let found
    const searchSectionsTree = (val) => {
      if (found) {
        return
      }
      if (val.id === a.id || val.id === b.id) {
        found = val
        return
      }
      val.items.forEach(searchSectionsTree)
    }
    sectionsTree.forEach(searchSectionsTree)

    if (found && found.id === a.id) {
      return -1
    } else if (found && found.id === b.id) {
      return 1
    }
    return 0
  })
}
export const getSectionsOfType = (types, sections) => {
  if (!Array.isArray(types)) {
    types = [types]
  }
  return sections.filter((section) => {
    if (section.data && section.data.mediaTypes) {
      return section.data.mediaTypes.some((type) => types.includes(type.name))
    }
    return false
  })
}
// export const movieType = () => {}
// export const tvType = () => {}
// export const audioType = () => {}
