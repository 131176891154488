<template>
<div class="flex flex-column align-items-end">
  <LanguageSelector v-model="language" :sort="languageSort"/>
</div>
<TabView id="showTabPanel">
  <TabPanel>
    <template #header>
      <!-- TODO: Make this work -->
      <span :class="{'p-error': areThereAnyNewVideoGenre}">
        <i v-if="areThereAnyNewVideoGenre" class="mr-1 pi pi-exclamation-triangle"></i> Movies / TV
      </span>
    </template>
    <div class="flex flex-wrap">
      <div v-for="genre in videoGenresList" :key="`genreVid-${genre.id}`" class="m-2">
        <Checkbox :id="genre.id" :name="genre.nameToDisplay" :class="{'c-checkbox': true, 'mr-2': true, 'p-invalid': isNewGenre(genre.id)}" :modelValue="genre.overloaded" @update:modelValue="toggleOverload($event, genre)" binary />
        <InputText :class="{'c-input': true, 'p-invalid': genre.isInvalid}" :disabled="!genre.overloaded" :placeholder="genre.nameToDisplay" :modelValue="genre.overloadValue" @update:modelValue="inputOverload($event, genre)" />
      </div>
    </div>
  </TabPanel>
  <TabPanel>
    <template #header>
      <!-- TODO: Make this work -->
      <span :class="{'p-error': areThereAnyNewAudioGenre}">
        <i v-if="areThereAnyNewAudioGenre" class="mr-1 pi pi-exclamation-triangle"></i> Audio
      </span>
    </template>
    <div class="flex flex-wrap">
      <div v-for="genre in audioGenresList" :key="`genreAudio-${genre.id}`" class="m-2">
        <Checkbox :id="genre.id" :name="genre.nameToDisplay" :class="{'c-checkbox': true, 'mr-2': true, 'p-invalid': isNewGenre(genre.id)}" :modelValue="genre.overloaded" @update:modelValue="toggleOverload($event, genre)" binary />
        <InputText :class="{'c-input': true, 'p-invalid': genre.isInvalid}" :disabled="!genre.overloaded" :placeholder="genre.nameToDisplay" :modelValue="genre.overloadValue" @update:modelValue="inputOverload($event, genre)" />
      </div>
    </div>
  </TabPanel>
</TabView>
</template>

<script>
import { onMounted, ref, computed, inject } from 'vue'
import Checkbox from 'primevue/checkbox'
import { useStore } from 'vuex'
import { until } from '@vueuse/core'
import InputText from 'primevue/inputtext'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

import LanguageSelector from '@/components/resource/LanguageSelector.vue'
import { useFlowEditor } from '@/compositions'
import Log from '@/services/logger'

export default {
  components: {
    TabView, TabPanel, Checkbox, InputText, LanguageSelector
  },
  props: ['editorKey', 'ifeSystemId'],
  inheritAttrs: false,
  setup (props) {

    const { fieldGet, fieldObjDel, fieldObjSet, ...flowEditorAPI } = useFlowEditor(props.editorKey)

    const store = useStore()
    const pxstream = inject('pxstream')

    const language = ref('2')
    const audioGenres = ref([])
    const videoGenres = ref([])

    const ifeISO = computed(() => {
      return fieldGet(`ifeSystems.${props.ifeSystemId}.ife.iso`)
    })

    const genreOverloads = computed({
      get: () => fieldGet(`ifeSystems.${props.ifeSystemId}.genreOverload`),
      set: () => { Log('Trying to set genreOverload...') }
    })

    const getGenres = async () => {
      await until(() => store.getters['resource/isReady']).toBe(true)
      const contentTypes = store.getters['resource/contentTypes']
      let audioIdList = []
      let videoIdList = []
      if (contentTypes) {
        contentTypes.map(({id, format}) => {
          if (format.name === "Audio") {
            audioIdList.push(id)
          } else if (format.name === "Movie" || format.name === "TV") {
            videoIdList.push(id)
          }
        })
        try {
          const videoQryBuilder = pxstream.tools.createQueryBuilder({rows: -1})
          videoQryBuilder.addFilter('contentTypes.id', 'contains', videoIdList)
          const { data: mVideoGenreList } = await pxstream.resource.getGenres(videoQryBuilder.build())
          videoGenres.value = mVideoGenreList
        } catch (e) {
          Log.Error(e)
        }

        try {
          const audioQryBuilder = pxstream.tools.createQueryBuilder({rows: -1})
          audioQryBuilder.addFilter('contentTypes.id', 'contains', audioIdList)
          const { data: mAudioGenreList } = await pxstream.resource.getGenres(audioQryBuilder.build())
          audioGenres.value = mAudioGenreList
        } catch (e) {
          Log.Error(e)
        }
      }
    }

    const videoGenresList = computed(() => {
      Log('compute videoGenres...')
      return videoGenres.value.map((genre) => {
        let overloadedData = genreOverloads.value[genre.id]
        genre.overloaded = !!overloadedData
        genre.overloadValue = (overloadedData?.overload && overloadedData.overload[language.value]?.name) ? overloadedData.overload[language.value].name : ""
        genre.nameToDisplay = genre.i18n[language.value] ? genre.i18n[language.value].name : genre.name
        genre.isInvalid = computed(() => {
          return isNewGenreLocal(genre.id)
        })
        return genre
      })
    })

    const audioGenresList = computed(() => {
      Log('compute audioGenres...')
      return audioGenres.value.map((genre) => {
        // TODO: remove hardcoded indexes
        let overloadedData = genreOverloads.value[genre.id]
        genre.overloaded = !!overloadedData
        genre.overloadValue = (overloadedData?.overload && overloadedData.overload[language.value]?.name) ? overloadedData.overload[language.value].name : ""
        genre.nameToDisplay = genre.i18n[language.value] ? genre.i18n[language.value].name : genre.name
        genre.isInvalid = computed(() => {
          return isNewGenreLocal(genre.id)
        })
        return genre
      })
    })

    const areThereAnyNewVideoGenre = computed(() => {
      return !!videoGenresList.value.find(genre => genre.isInvalid.value)
    })

    const areThereAnyNewAudioGenre = computed(() => {
      return !!audioGenresList.value.find(genre => genre.isInvalid.value)
    })

    const isNewGenre = inject('isNewGenre')
    const deleteNewGenre = inject('deleteNewGenre')

    const isNewGenreLocal = (genreID) => {
      return isNewGenre(ifeISO.value, genreID)
    }

    const languageSort = (a, b) => {
      if (!a.order && b.order) {
        return 1
      } else if (a.order && !b.order) {
        return -1
      } else if (a.order && b.order) {
        return a.order-b.order
      }
      return 0
    }

    const inputOverload = (nameOverloaded, genre) => {
      let overloaded = genreOverloads.value[genre.id]

      if (overloaded) {
        if (nameOverloaded) {
          fieldObjSet({
            field: `ifeSystems.${props.ifeSystemId}.genreOverload.${genre.id}.overload`,
            key: language.value,
            value: {name: nameOverloaded}
          })
        } else {
          fieldObjDel({ field: `ifeSystems.${props.ifeSystemId}.genreOverload.${genre.id}.overload`, key: language.value })
        }
      }
    }

    const toggleOverload = (overloaded, genre) => {
      if (isNewGenreLocal(genre.id)) {
        deleteNewGenre(ifeISO.value, genre.id)
      }
      if (overloaded) {
        fieldObjSet({ field: `ifeSystems.${props.ifeSystemId}.genreOverload`, key: genre.id, value: { id: genre.id, genre }})
      } else {
        fieldObjDel({ field: `ifeSystems.${props.ifeSystemId}.genreOverload`, key: genre.id })
      }
    }

    onMounted(() => {
      getGenres()
    })

    return {
      language,
      videoGenresList,
      audioGenresList,
      areThereAnyNewVideoGenre,
      areThereAnyNewAudioGenre,
      toggleOverload,
      inputOverload,
      languageSort,
      isNewGenre: isNewGenreLocal,
      ...flowEditorAPI,
    }
  }
}
</script>

<style lang="scss" scoped>
.c-input {
  font-weight: bold;
  &::placeholder {
    font-weight: 400;
  }
}

.c-checkbox {
  vertical-align: middle;
}
</style>