<template>
  <div>
    <Dialog
      v-model:visible="showIt"
      style="width:500px"
      @hide="$emit('update:modelValue', false);resetFields()"
      modal
      dismissableMask
      :closable="false"
      :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">Add Project</h3>
        </div>
      </template>

      <form class="p-fluid" @keyup.enter="create" >
        <div class="fluid">

          <div class="field grid">
            <label for="name"
            class="col-fixed font-bold justify-content-end"
            :class="{'p-error':nameError}"
            style="width:150px">{{ $t('forms.name') }}</label>
            <div class="col">
              <InputText id="name" type="text"
                v-model="name"
                :class="{'p-invalid': nameError}"
                :disabled="isLoading"
              />
              <small v-if="nameError" class="p-error">
                {{nameError}}
              </small>
            </div>
          </div>

        </div>
      </form>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text" @click="showIt = false"/>
          <Button :label="$t('actions.submit')" icon="pi pi-check" :loading="isLoading" @click="create()"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, computed, watch, inject } from 'vue'
import InputText from "primevue/inputtext"

import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'
import { useRouter } from 'vue-router'

export default {
  name: 'ProjectNew',
  props: ['modelValue'],
  components: {
    InputText
  },
  setup (props) {
    const name = ref('')
    const showIt = ref(false)
    const isLoading = ref(false)
    const errorCaught = ref('')
    const rules = {
      name: {
        required
      }
    }
    const pxstream = inject('pxstream')
    const router = useRouter()
    const v$ = useVuelidate(rules, { name }, { $scope: false })

    // Computed
    const nameError = computed(() => {
      if (errorCaught.value) return errorCaught.value
      return v$.value.name && v$.value.name.$dirty && v$.value.name.required.$invalid ? 'Name is required.' : ''
    })

    // Methods
    const resetFields = () => {
      v$.value.$reset()
      errorCaught.value = ''
      name.value = ''
    }

    const create = () => {
      v$.value.$touch()
      if (v$.value.$invalid) {
        return
      }

      errorCaught.value = ''
      isLoading.value = true

      pxstream.project.createProject({ name: name.value })
        .then(({id}) => {
          showIt.value = false
          router.push(`/projects/${id}`)
        })
        .catch((err) => {
          if (err.response.data.error === 'AlreadyExist') {
            errorCaught.value = 'This project name already exist'
          }
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    // Watchers
    watch(() => props.modelValue, (val) => {
      showIt.value = val
    })

    return {
      name,
      showIt,
      isLoading,
      errorCaught,
      v$,
      nameError,
      create,
      resetFields
    }
  },
}
</script>
