<template>
  <div>
    <slot name="header"
      :hasUpdate="hasUpdate"
      :hasError="hasError"
      :hasErrorFrom="hasErrorFrom"
      :hasLinksError="hasLinksError"
      :saveFlowBuild="saveFlowBuild" />
    <TabEditor :editorKey="editorKey" :editorMain="editorMain">
      <Tab content="ProjectGeneral">
        <template #header>
          <span :class="{'p-error': hasErrorFrom('ProjectGeneral')}">
            <i v-if="hasErrorFrom('ProjectGeneral')" class="mr-1 pi pi-exclamation-triangle"></i> General
          </span>
        </template>
      </Tab>
      <Tab content="ProjectDetails">
        <template #header>
          <span>
            Details
          </span>
        </template>
      </Tab>
      <Tab content="ProjectIFEProducts">
        <template #header>
          <span>
            IFE Products
          </span>
        </template>
      </Tab>
      <Tab v-if="isExportCspVisible">
        <template #header>
          <span :class="{'p-error': hasErrorFrom('ProjectExport') || areThereNewGenres}">
            <i v-if="hasErrorFrom('ProjectExport') || areThereNewGenres" class="mr-1 pi pi-exclamation-triangle"></i> Export CSP
          </span>
        </template>
        <template #content><ProjectExport :editorKey="editorKey" isExportCspVisible /></template>
      </Tab>
    </TabEditor>
  </div>
</template>

<script>
import { ref, computed, onMounted, inject, provide } from 'vue'
import { useStore } from 'vuex'
import { until } from '@vueuse/core'
import { useFlowBuilder, userExporterProjectReports } from "@/compositions"

import TabEditor from '@/components/base/TabEditor.vue'
import ProjectExport from './export/Main.vue'
import Log from '@/services/logger'

function onCreate (doc) {
  return {
    name: doc.name
  }
}

export default {
  name: 'ProjectEditingCore',
  props: {
    doc: Object,
    flow: String,
    editorMain: String
  },
  emits: ['has-update', 'field-change'],
  components: {
    TabEditor,
    Tab: TabEditor.Tab,
    ProjectExport
  },
  setup (props, { emit }) {
    const { fieldGet, setError, ...flowBuilderAPI } = useFlowBuilder(props.flow, props.doc, {
      editorMain: props.editorMain,
      onCreate,
      onFieldChange: (field, value) => {
        emit('field-change', { field, value })
      },
      onUpdateChange: ({hasUpdate, at}) => {
        emit('has-update', { hasUpdate, at})
      },
      actionFields: [
        { field: 'ifeSystems', action: 'ife-system', actionFields: [
          { field: 'rules', action: 'to-raw' },
          { field: 'genreOverload', action: 'genre-overload', actionFields: [
            { field: 'genre', action: 'to-raw' },
            { field: 'overload', action: 'to-i18n', key: 'overload' }
          ] }
        ] },
      ]
    })

    const store = useStore()
    const pxstream = inject('pxstream')

    const newGenres = ref({})

    const isExportCspVisible = computed(() => {
      let isCSP = fieldGet('type') === 'csp'
      !isCSP ? setError(null, 'ProjectExport') : ''
      return isCSP
    })

    const areThereNewGenres = computed(() => {
      return Object.keys(newGenres.value).length !== 0
    })

    const isNewGenre = (ifeID, genreID) => {
      if (newGenres.value[ifeID]) {
        if (genreID) {
          return !!newGenres.value[ifeID][genreID]
        } else {
          return !!newGenres.value[ifeID]
        }
      }

      return false
    }

    const deleteNewGenre = (ifeID, genreID) => {
      if (newGenres.value[ifeID]) {
        delete newGenres.value[ifeID][genreID]
        if (Object.keys(newGenres.value[ifeID]).length === 0) {
          delete newGenres.value[ifeID]
        }
      }
    }

    provide('isNewGenre', isNewGenre)
    provide('deleteNewGenre', deleteNewGenre)

    onMounted(async () => {
      try {
        const videoAndAudioContentTypeIds = []
        const {data: reports} = await userExporterProjectReports(fieldGet('id'))
        if (Array.isArray(reports)) {

          await until(() => store.getters['resource/isReady']).toBe(true)
          const contentTypes = store.getters['resource/contentTypes']
          contentTypes.map(({id, format}) => {
            if (format.name === "Audio" || format.name === "Movie" || format.name === "TV") {
              videoAndAudioContentTypeIds.push(id)
            }
          })

          for (let i = 0; i < reports.length; i++) {
            const qry = pxstream.tools.createQueryBuilder({})
            qry.addFilter('created_at', 'gt', [reports[i].startedAt])
            qry.addFilter('contentTypes.id', 'contains', videoAndAudioContentTypeIds)
            const {data: genres} = await pxstream.resource.getGenres(qry)
            if (genres && genres.length) {
              newGenres.value[reports[i].args.IFESystem] = genres.reduce((acc, val) => {
                acc[val.id] = val
                return acc
              }, {})
            }
          }
        }
      } catch (err) {
        Log.Error(err)
      }
    })

    return {
      isExportCspVisible,
      areThereNewGenres,
      ...flowBuilderAPI,
    }
  }
}
</script>
