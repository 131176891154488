<template>
<DataTable
  :totalRecords="0"
  :value="rules"
  v-bind="[]"
>
  <template #empty>
      No rule found.
  </template>

  <template #loading>
      Loading rules. Please wait.
  </template>

  <Column header="Type">
    <template #body="{data}">
      <p field="value">
        {{ getDisplayType(data) }}
      </p>
    </template>
  </Column>

  <Column header="Value">
    <template #body="{data}">
      <p field="value">
        {{ getDisplayValue(data) }}
      </p>
    </template>
  </Column>

  <Column header="Sections">
    <template #body="{data}">
      <p field="sections">
        <span v-if="loadingSections">Loading...</span>
        <span v-else>
          {{ getDisplaySections(data) }}
        </span>
      </p>
    </template>
  </Column>
  <Column>
    <template #header>
      <div class="flex justify-content-end w-full"></div>
    </template>

    <template #body="slotProps">
      <div class="flex justify-content-end w-full">
        <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="editRule(slotProps.data, slotProps.index)" />
        <Button
        icon="pi pi-trash"
        class="p-button-rounded p-button-danger p-button-outlined"
        :loading="slotProps.data.__isRemoving"
        @click="confirmRemove(slotProps.data, slotProps.index)"
        />
      </div>
    </template>
  </Column>
  <template #footer>
    <Button :disabled="!cspMenuId" icon="pi pi-plus" class="p-button-outlined" label="ADD RULE" @click="addNewRule()"/>
  </template>
</DataTable>
<RuleEditorModal :systems="systemsAvailable" :loadingSections="loadingSections" :sections="sectionsAvailableSorted" :cspMenuId="cspMenuId" v-model:visible="showRuleEditor" @addRule="onAddRule" @updateRule="onUpdateRule" :rule="ruleToEdit" />
</template>

<script>
import { ref, inject, computed, watch } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useConfirm } from 'primevue/useconfirm'
import RuleEditorModal from './RuleEditorModal.vue'
import { useFlowEditor } from '@/compositions'
import ruleTypes, {
  K_PRIORITY_RULE_TYPE,
  K_SYSTEMS_RULE_TYPE,
  K_PLAY_PERIOD_RULE_TYPE
} from '@/pages/projects/core/ruleTypes'
import { getSectionsSortedByTree } from '@/pages/projects/core/sectionUtils'
import Log from '@/services/logger'

export default {
  components: {
    DataTable, Column, RuleEditorModal
  },
  props: ['ifeSystemId', 'editorKey'],
  inheritAttrs: false,
  setup (props) {

    const { fieldGet, fieldSetArrayItem, fieldPush, fieldSplice } = useFlowEditor(props.editorKey)

    const cspMenuId = computed(() => {
      return fieldGet(`ifeSystems.${props.ifeSystemId}.cspMenu`)?.id
    })

    const rules = computed(() => {
      return fieldGet(`ifeSystems.${props.ifeSystemId}.rules`) || []
    })

    const sectionsAvailable = ref({})
    const sectionsAvailableSorted = ref([])
    const systemsAvailable = ref([])

    const loadingSections = ref(false)
    const showRuleEditor = ref(false)
    const ruleToEdit = ref(null)
    const ruleIndexToEdit = ref(null)
    const confirm = useConfirm()
    const pxstream = inject('pxstream')

    watch(cspMenuId, async () => {
      if (parseInt(cspMenuId.value)) {
        loadingSections.value = true
        try {
          const {data} = await pxstream.portalmanager.getMenuOne(cspMenuId.value, true)
          sectionsAvailable.value = data.cycle?.sections ?? {}
          sectionsAvailableSorted.value = getSectionsSortedByTree(data.cycle)
        } catch (e) {
          Log('Error getting sections..', e)
        } finally {
          loadingSections.value = false
        }
      }
    }, { immediate: true})

    watch(() => props.ifeSystemId, async () => {
      const {data} = await pxstream.resource.getIfe(props.ifeSystemId)
      systemsAvailable.value = data.ifeProducts
    }, { immediate: true })

    const addNewRule = () => {
      ruleToEdit.value = null
      ruleIndexToEdit.value = 0
      showRuleEditor.value = true
    }

    const onUpdateRule = (rule) => {
      fieldSetArrayItem({field: `ifeSystems.${props.ifeSystemId}.rules`, value: rule, index: ruleIndexToEdit.value})
    }

    const onAddRule = (rule) => {
      fieldPush({field: `ifeSystems.${props.ifeSystemId}.rules`, value: rule, key: 'type'})
    }

    const editRule = (val, index) => {
      ruleToEdit.value = val
      ruleIndexToEdit.value = index
      showRuleEditor.value = true
    }

    const confirmRemove = (data, index) => {
      confirm.require({
        message: `Are you sure you want to delete this rule?`,
        header: `Delete ${data.name}?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          remove(index)
        },
        reject: () => {}
      });
    }

    const remove = async (index) => {
      fieldSplice({ field: `ifeSystems.${props.ifeSystemId}.rules`, filter: (rule, idx) => (idx !== index)})
    }

    const getDisplayType = ({type}) => ruleTypes.find((t) => t.value === type).name

    const getDisplayValue = ({type, value}) => {
      if (type === K_PRIORITY_RULE_TYPE) return value ? 'YES' : 'NO'
      else if (type === K_PLAY_PERIOD_RULE_TYPE) return `${value.periodFrom} - ${value.periodTo || 'TBD'}`
      else if (type === K_SYSTEMS_RULE_TYPE) return value?.length === systemsAvailable.value.length ? 'ALL' : value.map((system) => systemsAvailable.value.find((s) => s.id === system.id)?.name).join(', ')
      else return value ? 'ON' : 'OFF'
    }

    const getDisplaySections = ({ all, sections }) =>  {
      if (all) return 'ALL'
      return sections.map(({id}) => sectionsAvailable.value[id]?.name).join(', ')
    }


    return {
      showRuleEditor,
      cspMenuId,
      rules,
      ruleToEdit,
      loadingSections,
      sectionsAvailableSorted,
      systemsAvailable,
      onUpdateRule,
      onAddRule,
      addNewRule,
      editRule,
      confirmRemove,
      getDisplayType,
      getDisplayValue,
      getDisplaySections,
    }
  }
}
</script>
