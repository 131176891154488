<template>
  <div>
    <Dialog
      v-model:visible="showIt"
      style="width:500px"
      @hide="$emit('update:modelValue', false);resetFields()"
      modal
      dismissableMask
      :closable="false"
      :draggable="false">
      <template #header>
        <div class="flex flex-auto flex-column text-700">
          <i class="pi pi-file-o text-center" style="fontSize: 3em"/>
          <h3 class="text-center">Export Project</h3>
          <!-- <div>Status: {{lastEvent.status}}</div> -->
        </div>
      </template>

      <!-- {{ lastEvent }} -->
      <!-- {{ newAskedExportUUID }} -->
      <div v-if="newAskedExportUUID || lastEvent.status === 'wip' || isNewExport">
        <div v-if="stages && stages.length">
          <div v-for="(stage, stageIdx) of stages" :key="stageIdx" class="px-project-stages">

            <div class="p-message p-component" :class="`p-message-${getStageColor(stageIdx)}`" role="alert">
              <div class="p-message-wrapper">
                <span class="p-message-icon" :class="getStageMessageIcon(stageIdx)"></span>
                <div class="p-message-text">{{stage.Label}}</div>
              </div>
            </div>

            <!-- <Message :severity="getStageColor(stageIdx)" :closable="false" icon="pi pi-send">{{stage.Label}}</Message> -->
            <div class="pl-5" v-if="stages[currentStageIndex] && stage.ID === stages[currentStageIndex].ID">
              <div v-for="(step, stepIdx) of stage.Steps" :key="`${stageIdx}:${stepIdx}`" class="p-message p-component" :class="`p-message-${getStepColor(stepIdx)}`" role="alert">
                <div class="p-message-wrapper">
                  <span class="p-message-icon" :class="getStepMessageIcon(stepIdx)"></span>
                  <div class="p-message-text">{{step.Label}}</div>
                </div>
              </div>
              <!-- <Message v-for="(step, stepIdx) of stage.Steps" :key="`${stageIdx}:${stepIdx}`" :severity="getStepColor(stepIdx)" :closable="false">{{step.Label}}</Message> -->
            </div>
          </div>
          <br/>
          <Textarea rows="5" v-model="logs" disabled class="w-full"/>
        </div>
        <div v-else>
          Loading stages...
        </div>
      </div>
      <form v-else class="p-fluid" @keyup.enter="createExport" >
        <div class="fluid">


          <div class="field grid">
            <label for="airlineCode" :class="{'col-fixed justify-content-end': true}" style="width:150px">Airline Code:</label>
            <div class="col">
              <InputText id="airlineCode" type="text" v-model="airlineCode" :disabled="true" />
            </div>
          </div>

          <div class="field grid">
            <label for="IFESystem" :class="{'p-error':IFESystemError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">IFE System:</label>
            <div class="col">
              <Dropdown v-model="IFESystem" :options="ifeSystems" optionValue="ife.id" optionLabel="ife.name" placeholder="Select an IFE System" class="w-full" :class="{'p-invalid': IFESystemError}" :disabled="isLoading" />
              <small v-if="IFESystemError" class="p-error">{{IFESystemError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="menu" :class="{'col-fixed justify-content-end': true}" style="width:150px">Menu:</label>
            <div class="col">
              <InputText id="menu" type="text" v-model="menuName" :disabled="true" />
            </div>
          </div>

          <div class="field grid">
            <label for="cycle" :class="{'p-error':cycleError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">Cycle:</label>
            <div class="col">
              <Dropdown v-model="cycle" :options="cycles" optionValue="id" optionLabel="name" placeholder="Select a cycle" class="w-full" :class="{'p-invalid': cycleError}" :disabled="isLoading" />
              <small v-if="cycleError" class="p-error">{{cycleError}}</small>
            </div>
          </div>

          <div class="field grid">
            <label for="typeOfFile" :class="{'p-error':typeOfFileError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">Type of file:</label>
            <div class="col">
              <Dropdown v-model="typeOfFile" :options="fileTypes" optionValue="iso" optionLabel="name" placeholder="Select a type of file" class="w-full" :class="{'p-invalid': typeOfFileError}" :disabled="isLoading" />
              <small v-if="typeOfFileError" class="p-error">{{typeOfFileError}}</small>
            </div>
          </div>

          <div v-if="isPreviousCycle" class="field grid">
            <div class="col">
              <SelectButton id="creationMode" class="p-primary-button" v-model="selectionMode" :options="selectionModes" optionLabel="label" optionDisabled="disabled" />
            </div>
          </div>

          <div class="field grid" v-if="(isExportMode && isPreviousCycle) || isLatestCycle">
            <label for="version" :class="{'p-error':versionError, 'col-fixed font-bold justify-content-end': true}" style="width:150px">Version:</label>
            <div class="col">
              <InputText id="version" type="text" :modelValue="version" v-debounce:500ms="(val) => (version = val)" :class="{'p-invalid': versionError}" :placeholder="lastVersion" :disabled="isLoading" />
              <small v-if="versionError" class="p-error">{{versionError}}</small>
            </div>
          </div>
          
          <div class="field grid" v-else>
            <label for="version" :class="{'col-fixed font-bold justify-content-end': true}" style="width:150px">Version:</label>
            <div class="col">
              <Dropdown v-model="selectedCycleVersion" :options="cycleVersions" optionLabel="Version" placeholder="Select a version" class="w-full" :disabled="isLoading" />
            </div>
          </div>

          <div class="field grid" v-if="isExportMode">
            <label for="comments" :class="{'col-fixed justify-content-end': true}" style="width:150px">Comments:</label>
            <div class="col">
              <InputText id="comments" type="text" :modelValue="comments" v-debounce:500ms="(val) => (comments = val)" :placeholder="lastComment" :disabled="isLoading" />
            </div>
          </div>

          <div class="field grid" v-if="isExportMode">
            <label for="prefixFile" :class="{'col-fixed justify-content-end': true}" style="width:150px">Prefix File:</label>
            <div class="col">
              <InputText id="prefixFile" type="text" :modelValue="prefixFile" v-debounce:500ms="(val) => (prefixFile = val)" :placeholder="lastFilePrefix" :disabled="isLoading" />
            </div>
          </div>

        </div>
      </form>

      <Message v-if="errorCaught" severity="error" :closable="false">
        {{ errorCaught }}
      </Message>

      <template #footer>
        <div class="flex justify-content-between">
          <Button :label="$t('actions.close')" icon="pi pi-times" class="p-button-text" @click="$emit('update:modelValue', false); isNewExport = false; isAutoExported = false"/>
          <Button v-if="((!isAutoExported && isLatestCycle) || (isPreviousCycle && isExportMode))" :label="$t('actions.export')" icon="pi pi-check" :loading="lastEvent.status === 'wip'" :disabled="typeOfFileError || isNewExport" @click="createExport()"/>
          <Button v-else :disabled="!(isLatestCycle ? currentUUID : selectedCycleVersion.UUID)" :label="$t('actions.download')" icon="pi pi-check" @click="downloadReport(isLatestCycle ? currentUUID : selectedCycleVersion.UUID)"/>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, computed, watch, onUnmounted, inject, watchEffect } from 'vue'
import { useI18n } from 'vue-i18n'
import InputText from "primevue/inputtext"
import Textarea from 'primevue/textarea';
// import InputMask from "primevue/inputmask"
import Message from "primevue/message"
import Dropdown from "primevue/dropdown"
import SelectButton from "primevue/selectbutton"
import { useVuelidate } from "@vuelidate/core"
import { required } from '@vuelidate/validators'
// import { useRouter } from 'vue-router'
import { useExporterProjectEvent, userExporterProjectReports } from '@/compositions'
import { getDirective as vueDebounce } from 'vue-debounce'
import http from '@/services/http'
import Log from '@/services/logger'

export default {
  name: 'ExportDialog',
  props: ['modelValue', 'doc'],
  components: {
    InputText, Message, Dropdown, Textarea, SelectButton
  },
  directives: {
    debounce: vueDebounce(3)
  },
  setup (props) {
    const pxstream = inject('pxstream')
    const { t } = useI18n()
    const logs = ref('')
    const prefixFile = ref('')
    const IFESystem = ref(null)
    const cycle = ref(null)
    const typeOfFile = ref(null)
    const version = ref('')
    const comments = ref('')

    const lastVersion = ref('')
    const lastComment = ref('')
    const lastFilePrefix = ref('')

    const showIt = ref(false)
    const isLoading = ref(false)
    const errorCaught = ref('')

    // const exportStatus = ref('')
    const isNewExport = ref(false)
    const isAutoExported = ref(false)
    const currentStageIndex = ref(-1)
    const currentStepIndex = ref(-1)

    let ifeList = []

    const DOWNLOAD_MODE = { label: t('actions.download'), disabled: false }
    const EXPORT_MODE = { label: t('actions.export'), disabled: false }
    const selectionModes = [DOWNLOAD_MODE, EXPORT_MODE]
    const selectionMode = ref(EXPORT_MODE)

    const reports = ref([])
    const reportsByCycle = ref([])
    const stages = ref(null)
    const cycles = ref([])
    const latestCycle = ref(null)
    const previousCycle = ref(null)
    const fileTypes = ref([])
    const currentUUID = ref('')
    const cycleVersions = ref(null)
    const selectedCycleVersion = ref('')
  
    // newAskedExportUUID allows to display progress widget while export report with
    // a valid schedule (stages) is available and to not stay on form
    // Futhermore, when one receveid event has the same uuuid as newAskedExportUUID,
    // force reset form fields.
    const newAskedExportUUID = ref('')

    const rules = {
      IFESystem: {
        required
      },
      cycle: {
        required
      },
      typeOfFile: {
        required
      },
      version: {
        required
      }
    }

    userExporterProjectReports(props.doc.id)
    .then(({data}) => {
      reports.value = data || []
    })
    .catch(err => {
      Log.Error(err)
    })

    const { lastEvent, close: closeService } = useExporterProjectEvent(props.doc.id, e => {
      if (newAskedExportUUID.value === e.uuid) {
        newAskedExportUUID.value = ''
        resetFields()
      }

      if (e.log && isNewExport.value) {
        logs.value += `\n - ${e.log}`
      }

      if (currentUUID.value !== e.uuid && !(e.status === 'done' || e.status === 'failed')) {
        isNewExport.value = true
        pxstream.service
          .getExportSteps(e.uuid)
          .then(({data}) => {
            // Check data has valid stages
            if (data.schedule.Stages) {
              currentUUID.value = e.uuid
              stages.value = data.schedule.Stages
            }
          })
          .catch(() => {
            currentUUID.value = ''
            stages.value = null
          })
      }

      // Find current stage and step indexes from stages
      currentStageIndex.value = -1
      currentStepIndex.value = -1
      if (stages.value && stages.value.length) {
        for (let stageIdx = 0; stageIdx < stages.value.length; stageIdx++) {
          if (stages.value[stageIdx].ID === e.stageId) {
            currentStageIndex.value = stageIdx
            for (let stepIdx = 0; stepIdx < stages.value[stageIdx].Steps.length; stepIdx++) {
              if (stages.value[stageIdx].Steps[stepIdx].ID === e.stepId) {
                currentStepIndex.value = stepIdx
                break;
              }
            }
          }
        }
      }

      if (isNewExport.value && !isAutoExported.value && e.status === 'done') {
        isAutoExported.value = true;
        downloadReport(currentUUID.value)
      }
    })

    // const pxstream = inject('pxstream')
    // const router = useRouter()
    const v$ = useVuelidate(rules, { IFESystem, cycle, typeOfFile, version })

    // Computed

    const isExportMode = computed({
      get: () => Object.entries(selectionMode.value).toString() === Object.entries(EXPORT_MODE).toString()
    })

    const airlineCode = computed({
      get: () => { return props.doc.airlineCode }
    })
    
    const ifeSystems = computed({
      get: () => (Object.keys(props.doc.ifeSystems).map((id) =>
        props.doc.ifeSystems[id]
      )),
      //set: (value) => (fieldSet({field: 'ifeSystems', value }))
    })

    const menu = computed({
      get: () => {
        const ifeSystem = ifeSystems.value.find(ife => ife?.ife?.id === IFESystem.value)
        return (ifeSystem?.cspMenu ? ifeSystem.cspMenu : '')
      }
    })

    const menuName = computed({
      get: () => (menu.value ? menu.value.name : '')
    })

    const IFESystemError = computed(() => {
      return v$.value.IFESystem && v$.value.IFESystem.$dirty && v$.value.IFESystem.required.$invalid ? 'IFE System is required.' : ''
    })

    const cycleError = computed(() => {
      return v$.value.cycle && v$.value.cycle.$dirty && v$.value.cycle.required.$invalid ? 'Cycle is required.' : ''
    })

    const typeOfFileError = computed(() => {
      if (v$.value.typeOfFile && v$.value.typeOfFile.$dirty && v$.value.typeOfFile.required.$invalid)
        return 'Type Of File is required.'

      const currentType = fileTypes.value.find((type) => type.iso === typeOfFile.value) || { requires: [] }
      if (currentType.requires.length && isLatestCycle.value) {
        const hasRequirementsError = !reportsByCycle.value.some((report) => {
          return currentType.requires.includes(report.args.FileType)
        })
        if (hasRequirementsError) {
          return "Vous ne pouvez pas exporter un fichier Metadata tant que le CTR correspondant n'a pas été exporté."
        }
      }
      return ''
    })

    const versionError = computed(() => {
      return v$.value.version && v$.value.version.$dirty && v$.value.version.required.$invalid ? 'Version is required.' : ''
    })

    const projectId = computed(() => {
      return props.doc.id
    })

    const projectType = computed(() => {
      return props.doc.type
    })

    const isLatestCycle = computed(() => {
      return latestCycle.value === cycle.value
    })

    const isPreviousCycle = computed(() => {
      return previousCycle.value === cycle.value && cycle.value !== null
    })

    const refreshVersions = async () => {
      const currentCycle = cycle.value
      const currentTypeOfFile = typeOfFile.value
      selectedCycleVersion.value = ''
      if (!isLatestCycle.value) {
        await pxstream.service.getProjectVersions(projectId.value, cycle.value, typeOfFile.value)
          .then(({data}) => {
            if (currentCycle === cycle.value && currentTypeOfFile === typeOfFile.value) {
              cycleVersions.value = data
            }
          })
      }
    }

    // Methods
    const downloadReport = (uuid) => {
      pxstream.tools.downloadFile(http.getApiUrl(`/services/exporter/exports/${uuid}/result`))
    }

    const resetFields = () => {
      IFESystem.value = null
      version.value = ''
      comments.value = ''
      prefixFile.value = ''
      v$.value.$reset()
      errorCaught.value = ''
    }

    const createExport = async () => {
      v$.value.$touch()
      if (v$.value.$invalid || typeOfFileError.value) {
        return
      }

      errorCaught.value = ''
      isLoading.value = true

      try {
        const exportObj = {
          type: projectType.value,
          projectId: props.doc.id,
          version: version.value,
          comment: comments.value,
          args: {}
        }

        switch (exportObj.type) {
          case 'csp':
            exportObj.args = {
              filePrefix: prefixFile.value,
              ifeSystem: IFESystem.value,
              cycle: cycle.value,
              fileType: typeOfFile.value,
            }
            break;
        }

        const {uuid} = await pxstream.service.export(exportObj)
        newAskedExportUUID.value = uuid
        stages.value = null
      } catch (e) {
        errorCaught.value = e
      } finally {
        isLoading.value = false
      }
    }

    const getStageMessageIcon = (stageId) => {
      if (stageId < currentStageIndex.value || isAutoExported.value) {
        return 'pi pi-check'
      } else if (stageId === currentStageIndex.value) {
        return 'pi pi-spin pi-spinner'
      }

      return ''
    }

    const getStepMessageIcon = (stepId) => {
      if (stepId < currentStepIndex.value) {
        return 'pi pi-check'
      } else if (stepId === currentStepIndex.value) {
        return 'pi pi-spin pi-spinner'
      } else if (stepId > currentStepIndex.value) {
        return ''
      }

      return ''
    }

    const getStageColor = (stageId) => {
      if ( stageId < currentStageIndex.value || isAutoExported.value) {
        return 'success'
      } else if (stageId === currentStageIndex.value) {
        return 'warn'
      }

      return 'info'
    }

    const getStepColor = (stepId) => {
      if (stepId < currentStepIndex.value) {
        return 'success'
      } else if (stepId === currentStepIndex.value) {
        return 'warn'
      }

      return 'info'
    }

    const loadIFEFileTypes = () => {
      typeOfFile.value = null
      fileTypes.value = []
      if (IFESystem.value) {
        pxstream.resource.getIfe(IFESystem.value).then(data => {
          fileTypes.value = data?.data?.fileTypes || []
          if (fileTypes.value.length > 0) {
            typeOfFile.value = fileTypes.value[0].iso
          }
        })
      }
    }

    const loadCycles = () => {
      cycle.value = null
      cycles.value = []
      if (menu.value?.id) {
        pxstream.portalmanager.getCyclesInOneMenu(menu.value.id).then(data => {
          cycles.value = data?.data || []
          if (cycles.value.length > 0) {
            latestCycle.value = cycles.value[0].id
            cycle.value = cycles.value[0].id
          }
          if (cycles.value.length > 1) {
            previousCycle.value = cycles.value[1].id
          }
        })
      }
    }

    // Watchers
    watch(() => props.modelValue, (val) => {
      showIt.value = val
    })

    watch(cycle, async (newCycle) => {
      const result = reports.value.filter(report => 
        report.args.Cycle === newCycle
      )
      reportsByCycle.value = result
      refreshVersions()
    })

    watch(typeOfFile, () => {
      refreshVersions()
    })

    watch(IFESystem, () => {
      loadIFEFileTypes()
    })

    watch(menu, () => {
      loadCycles()
    })

    watchEffect(async () => {
      if (typeOfFile.value && IFESystem.value) {
        if (ifeList.length === 0) {
          const {data} = await pxstream.resource.getIfes({})
          ifeList = data
        }
        const currentIFESystemIso = ifeList.find(ife => parseInt(ife.id) === parseInt(IFESystem.value))?.iso ?? ''
        const lastReport = reports.value.find(report => report.args.IFESystem === currentIFESystemIso && report.args.Cycle === cycle.value && report.args.FileType === typeOfFile.value)
        lastVersion.value = lastReport?.version ?? ''
        lastComment.value = lastReport?.comment ?? ''
        lastFilePrefix.value = lastReport?.args.FilePrefix ?? ''
      } else if (lastVersion.value !== '' && lastComment.value !== '' && lastFilePrefix.value !== '') {
        lastVersion.value = ''
        lastComment.value = ''
        lastFilePrefix.value = ''
      }
    })

    onUnmounted(() => {
      closeService()
    })

    return {
      lastEvent,
      prefixFile,
      airlineCode,
      IFESystem,
      ifeSystems,
      menuName,
      lastVersion,
      lastComment,
      lastFilePrefix,
      cycle,
      typeOfFile,
      version,
      comments,
      showIt,
      stages,
      newAskedExportUUID,
      currentUUID,
      isNewExport,
      currentStageIndex,
      currentStepIndex,
      logs,
      isAutoExported,
      getStageMessageIcon,
      getStepMessageIcon,
      getStageColor,
      getStepColor,
      isLoading,
      errorCaught,
      downloadReport,
      cycles,
      fileTypes,
      projectId,
      v$,
      IFESystemError,
      cycleError,
      typeOfFileError,
      versionError,
      createExport,
      resetFields,
      reportsByCycle,
      isLatestCycle,
      isPreviousCycle,
      cycleVersions,
      selectedCycleVersion,
      selectionModes,
      selectionMode,
      isExportMode,
    }
  },
}
</script>

<style>
.px-project-stages .p-message-wrapper {
  padding: 5px!important;
}
.px-project-stages .p-message {
  margin: 5px 0!important;
}

.p-message-info {
    background-color: rgb(209 213 219) !important;
    color: #000 !important;
  }
</style>
